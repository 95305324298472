<template>
  <b-overlay class="row" :show="loading">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4>
            <i class="fa fa-save"></i>
            الجداول المحفوظة
            <b-link to="table" class="btn btn-relief-success">
              <i class="fa fa-plus"></i>
              إنشاء جدول جديد
            </b-link>
          </h4>
        </div>
        <div class="card-body">
          <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered">
              <thead>
                <th>عنوان الجدول</th>
                <th>آخر تعديل</th>
                <th>استعراض/طباعة</th>
                <th>تعديل</th>
                <th>حذف</th>
              </thead>
              <tbody>
                <tr v-for="table in tables" :key="table.id">
                  <td>
                    {{ table.title }}
                  </td>
                  <td>
                    {{
                      table.date.split("T")[0] +
                      " في الساعة " +
                      table.date.split("T")[1].split(".")[0]
                    }}
                  </td>
                  <td>
                    <button
                      class="btn btn-relief-primary btn-sm btn-block"
                      v-b-modal.modal-1
                      @click="
                        classes = table.classes.list;
                        teachers = table.teachers.list;
                        table_id = table._id;
                        renderClassrooms();
                      "
                    >
                      <i class="fa fa-print"></i>
                      استعراض/طباعة
                    </button>
                  </td>
                  <td>
                    <b-link
                      :to="'/table/#' + table.id"
                      class="btn btn-link btn-sm btn-block"
                    >
                      <i class="fa fa-edit"></i>
                      تعديل
                    </b-link>
                  </td>
                  <td>
                    <button
                      class="btn btn-link text-danger btn-sm btn-block"
                      @click="deleteTable(table.id)"
                    >
                      <i class="fa fa-trash"></i>
                      حذف
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      title="استعراض/طباعة"
      size="xl"
      ok-only
      ok-title="اغلاق"
    >
      <div class="row">
        <div class="col-12 col-lg-6 g">
          <div class="border g">
            <h4>جداول الفصول</h4>
            <div class="col-12 table-responsive">
              <table class="table table-hover table-bordered custom-table">
                <thead>
                  <th>الصف</th>
                  <th>الفصل</th>
                  <th>عرض/طباعة</th>
                </thead>
                <tbody>
                  <tr v-for="classroom in classrooms" :key="classroom.id">
                    <td>
                      {{ classroom.classname.title }}
                    </td>
                    <td>
                      {{ classroom.title }}
                    </td>
                    <td>
                      <a
                        :href="'/t/c/' + table_id + '/' + classroom.id + '/#'"
                        target="_blank"
                        class="btn btn-primary fa fa-print btn-sm"
                        style="border-radius: 0px"
                        title="طباعة"
                      ></a>
                      <a
                        :href="'/t/c/' + table_id + '/' + classroom.id"
                        target="_blank"
                        title="عرض"
                        class="btn btn-success fa fa-eye btn-sm"
                        style="border-radius: 0px"
                      ></a>
                      <a
                        @click="
                          shareLink('/t/c/' + table_id + '/' + classroom.id)
                        "
                        title="مشاركة"
                        class="btn btn-info fa fa-paper-plane btn-sm"
                        style="border-radius: 0px"
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 g">
          <div class="border g">
            <h4>جداول المعلمين</h4>
            <div class="col-12 table-responsive">
              <table class="table table-hover table-bordered custom-table">
                <thead>
                  <th>المعلم</th>
                  <th>الإسم المختصر</th>
                  <th>عرض/طباعة</th>
                </thead>
                <tbody>
                  <tr v-for="teacher in teachers" :key="teacher.id">
                    <td>
                      {{ teacher.fullname }}
                    </td>
                    <td>
                      {{ teacher.shortname }}
                    </td>
                    <td>
                      <a
                        :href="'/t/t/' + table_id + '/' + teacher.id + '/#'"
                        target="_blank"
                        class="btn btn-primary fa fa-print btn-sm"
                        style="border-radius: 0px"
                        title="طباعة"
                      ></a>
                      <a
                        :href="'/t/t/' + table_id + '/' + teacher.id"
                        target="_blank"
                        title="عرض"
                        class="btn btn-success fa fa-eye btn-sm"
                        style="border-radius: 0px"
                      ></a>
                      <a
                        @click="
                          shareLink('/t/t/' + table_id + '/' + teacher.id)
                        "
                        title="مشاركة"
                        class="btn btn-info fa fa-paper-plane btn-sm"
                        style="border-radius: 0px"
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
import { BLink, BModal, BOverlay } from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
export default {
  components: {
    BLink,
    BModal,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: true,
      tables: [],
      classrooms: [],
      classes: [],
      teachers: [],
      table_id: null,
    };
  },
  created() {
    this.fetchTables();
  },
  methods: {
    renderClassrooms() {
      var g = this;
      this.classrooms = [];
      g.classes.forEach((element) => {
        element.classrooms.forEach((element2) => {
          element2.classname = element;
          g.classrooms.push(element2);
        });
      });
    },
    fetchTables() {
      var g = this;
      g.loading = true;
      $.post(api + "/table/tables", {
        jwt: user.jwt,
      })
        .then(function (r) {
          r = JSON.parse(r);
          g.tables = r;
          g.loading = false;
        })
        .catch(function () {
          swal.fire(
            "حدث خطأ اثناء الاتصال بالخادم",
            "برجاء المحاولة مرة أخرى",
            "warning"
          );
          g.loading = false;
        });
    },
    deleteTable(id) {
      if (confirm("متأكد من حذف الجدول نهائياً؟")) {
        var g = this;
        g.loading = true;
        $.post(api + "/table/delete", {
          jwt: user.jwt,
          id: id,
        })
          .then(function (r) {
            g.fetchTables();
            g.loading = false;
          })
          .catch(function () {
            swal.fire(
              "حدث خطأ اثناء الاتصال بالخادم",
              "برجاء المحاولة مرة أخرى",
              "warning"
            );
            g.loading = false;
          });
      }
    },
    shareLink(link) {
      var g = this;
      $.post(api + "/table/share-link", {
        jwt: user.jwt,
        url: link,
      })
        .then(function (r) {
          prompt(
            `رابط المشاركة: `,
            `https://${window.location.hostname}/s/${r}`
          );
        })
        .catch(function () {
          swal.fire(
            "حدث خطأ اثناء الاتصال بالخادم",
            "برجاء المحاولة مرة أخرى",
            "warning"
          );
        });
    },
  },
};
</script>

<style>
</style>